import React from "react"
import { graphql } from "gatsby"
import MyHelmet from "../components/MyHelmet"

import IndexPageTemplate from "../templates/IndexPageTemplate"

const IndexPage = ({ data }) => {
  const fm = data.directus.homePage
  const posts = data.directus.products
  const pt = posts.slice(0, 3)
  // featured posts
  return (
    <>
      <MyHelmet title={fm.title} description={fm.subHeading} />
      <IndexPageTemplate
        heading={fm.heading}
        posts={pt}
        displaySpecialOffer={fm.displaySpecialOffer}
        subheading={fm.subHeading}
        image={fm.featuredImage}
        ticker={fm.tickerMessage}
      />
    </>
  )
}

export default IndexPage

export const indexPageQuery = graphql`
  query {
    directus {
      homePage {
        id
        title
        heading
        displaySpecialOffer
        tickerMessage
        subHeading
        featuredImage {
          id
        }
      }
      products(
        filter: {
          _and: [{ onOffer: { _eq: true } }, { hidden: { _eq: false } }]
        }
        sort: ["-date"]
      ) {
        id
        title
        date
        pinned
        slug
        subTitle
        featuredImage {
          id
        }
        body
      }
    }
  }
`
