import React from 'react';

const NewsCard = ({text}) => {
	return (
		<div className="max-w-md py-4 px-4 bg-green-800 shadow-lg rounded-lg my-20">
			<div>
				<div
					dangerouslySetInnerHTML={{ __html: text }}
				/>
			</div>
			{/* <div className="flex justify-end mt-4">
				<a
					href="#"
					className="bg-green-500 hover:bg-green-700 text-white text-xs text-center py-1 px-2 rounded"
				>
          Dismiss
				</a>
			</div> */}
		</div>
	);
};


export default NewsCard;
