import React from 'react';
import { Link } from 'gatsby';

import specialOfferImage from '../img/specialoffer.png';
import specialOfferArrows from '../img/arrows.png';

const SpecialOfferCard = () => {
	return (
		<div style={{ cursor: 'pointer' }} className="max-w-md py-4 px-4 bg-white shadow-lg rounded-lg my-20">
			<Link to="/offers-page" style={{ display: 'flex' }}>
				<div style={{marginTop: 8}}>
					<img className="pulse" style={{ width: 140 }} src={specialOfferArrows} />
				</div>
				<div  style={{ marginTop: 12 }}>
					<img className="pulse" style={{ width: 200, height: 93 }} src={specialOfferImage} />
				</div>
				<div style={{ marginTop: 36 }}>
					<h2>CLICK FOR SPECIAL OFFERS</h2>
				</div>
			</Link>
		</div>
	);
};

export default SpecialOfferCard;
